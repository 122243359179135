import { Box, Grid, LinearProgress, linearProgressClasses, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import { BAVContractDTO, BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';

interface Props {
  benefit: BenefitDTOBenefitEnum;
  contract: BAVContractDTO;
}

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.greyBackground,

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
  [`& .${linearProgressClasses.bar2Buffer}`]: {
    backgroundColor: theme.palette.primary.main,
    opacity: '50%',
  },
  '& .MuiLinearProgress-dashed': {
    backgroundImage: 'none',
    animation: 'none',
  },
}));

export const BAVProgress: React.FC<Props> = ({ contract, benefit }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const totalPremium = contract.employeePremiumGross + contract.vwlConversion + contract.employerGrant;
  const employeePremiumPrecent = contract.employeePremiumGross / totalPremium;

  return (
    <Box marginX={1.5} marginBottom={-0.6}>
      <Box display="flex" justifyContent="center" marginBottom={0.3}>
        <Typography variant="h2">{t(`benefit.${benefit}.paymentPeriod.${contract.paymentPeriod}`, { amount: totalPremium })}</Typography>
      </Box>

      <StyledLinearProgress
        variant="buffer"
        valueBuffer={((contract.employerGrant + contract.employeePremiumGross) / totalPremium) * 100}
        value={employeePremiumPrecent * 100}
      />

      <Grid container marginTop={0.5} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Grid display="flex" justifyContent={'space-between'} item>
          <Box display="flex" alignContent={'center'} gap={0.2}>
            <CircleIcon fontSize="small" color="primary" />
            <Typography align="left" variant="paragraph">
              {t(`benefit.${benefit}.progress.employeePremiumGross`)}
            </Typography>
          </Box>
          <Typography variant="paragraph">{t(`common:money`, { money: contract.employeePremiumGross })}</Typography>
        </Grid>

        <Grid display="flex" justifyContent={'space-between'} item>
          <Box display="flex" alignContent={'center'} position="relative" gap={0.2}>
            <CircleIcon fontSize="small" htmlColor={theme.palette.datePicker.border} sx={{ position: 'absolute' }} />
            <CircleIcon fontSize="small" htmlColor={theme.palette.primary.main} sx={{ opacity: '50%' }} />
            <Typography align="left" variant="paragraph">
              {t(`benefit.${benefit}.progress.employerGrant`)}
            </Typography>
          </Box>
          <Typography variant="paragraph">{t(`common:money`, { money: contract.employerGrant })}</Typography>
        </Grid>

        <Grid display="flex" justifyContent={'space-between'} item>
          <Box display="flex" alignContent={'center'} gap={0.2}>
            <CircleIcon fontSize="small" htmlColor={theme.palette.greyBackground} />
            <Typography align="left" variant="paragraph">
              {t(`benefit.${benefit}.progress.vwlConversion`)}
            </Typography>
          </Box>
          <Typography variant="paragraph">{t(`common:money`, { money: contract.vwlConversion })}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
