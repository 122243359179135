import { Box, Button, Card, FormControl, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum } from 'probonio-shared-ui/api';
import { RadioControl, SwitchControl } from 'probonio-shared-ui/component/form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { CheckboxControl } from '../../component/form/CheckboxControl';
import { TextFieldControl } from '../../component/form/TextFieldControl';
import { AVERAGE_SALARY } from './bavCalculator';

interface Props {
  yearOfBirth?: number;
  onSubmit: (values: FormValues) => void;
}

export interface FormValues {
  dateOfBirth: string;
  taxClass: number;
  numberOfKids: number;
  salary: number;
  isPayingChurchTax: boolean;
  typeOfHealthInsurance: CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum;
  nameOfHealthInsurance: string;
  acceptDataPrivacy: boolean;
  privateCareInsuranceRate: number;
  privateHealthInsuranceRate: number;
}

const TAX_CLASS_OPTIONS = [1, 2, 3, 4, 5];
const NUMBER_OF_KIDS_OPTIONS = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

export const BAVRequestEstimateForm: React.FC<Props> = ({ yearOfBirth, onSubmit }) => {
  const { t } = useTranslation('bavModule', { keyPrefix: 'requestEstimate' });
  const { control, watch, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      dateOfBirth: yearOfBirth ? `${yearOfBirth}-01-01` : undefined,
      taxClass: 1,
      numberOfKids: 0,
      salary: AVERAGE_SALARY,
      isPayingChurchTax: true,
      typeOfHealthInsurance: CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum.Public,
    },
  });

  const typeOfHealthInsurance = watch('typeOfHealthInsurance');

  return (
    <Box component="form" flexDirection="column" display="flex" flexGrow={1} onSubmit={handleSubmit(onSubmit)}>
      <Box paddingBottom={1} paddingX={1}>
        <Typography variant="body1">{t('lead')}</Typography>
      </Box>
      <Box flexGrow={1}>
        <Card sx={{ padding: 1, marginBottom: 2 }}>
          <Stack spacing={1}>
            <TextFieldControl
              control={control}
              name="dateOfBirth"
              type="date"
              label={t('dateOfBirth')}
              size="small"
              fullWidth
              rules={{ required: true }}
              inputProps={{ max: DateTime.now().minus({ years: 10 }).toISODate() }}
            />
            <Stack direction="row" spacing={1}>
              <TextFieldControl
                select
                control={control}
                name="taxClass"
                label={t('taxClass')}
                size="small"
                fullWidth
                rules={{ required: true }}
                sx={{ flex: 1 }}
              >
                {TAX_CLASS_OPTIONS.map(value => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextFieldControl>
              <TextFieldControl
                select
                control={control}
                name="numberOfKids"
                label={t('numberOfKids')}
                size="small"
                fullWidth
                rules={{ required: true }}
                sx={{ flex: 1 }}
              >
                {NUMBER_OF_KIDS_OPTIONS.map(value => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextFieldControl>
            </Stack>
            <TextFieldControl
              control={control}
              name="salary"
              type="number"
              label={t('salary')}
              size="small"
              fullWidth
              rules={{ required: true, min: 1 }}
              inputProps={{ min: 1 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <FormControl>
              <SwitchControl
                control={control}
                name="isPayingChurchTax"
                label={t('isPayingChurchTax')}
                controlLabelProps={{ labelPlacement: 'start', sx: { justifyContent: 'space-between', marginLeft: 0 } }}
              />
            </FormControl>
            <RadioControl
              control={control}
              name="typeOfHealthInsurance"
              label={t('typeOfHealthInsurance')}
              row
              options={[
                { value: CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum.Public, label: t('publicInsurance') },
                { value: CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum.Private, label: t('privateInsurance') },
              ]}
            />
            {typeOfHealthInsurance === CreateBAVEstimateRequestDTOTypeOfHealthInsuranceEnum.Public ? (
              <TextFieldControl
                control={control}
                name="nameOfHealthInsurance"
                label={t('nameOfHealthInsurance')}
                size="small"
                fullWidth
                rules={{ required: true }}
              />
            ) : (
              <>
                <TextFieldControl
                  control={control}
                  name="privateCareInsuranceRate"
                  type="number"
                  label={t('privateCareInsuranceRate')}
                  size="small"
                  fullWidth
                  rules={{ required: true, min: 0 }}
                  inputProps={{ min: 0 }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  }}
                />
                <TextFieldControl
                  control={control}
                  name="privateHealthInsuranceRate"
                  type="number"
                  label={t('privateHealthInsuranceRate')}
                  size="small"
                  fullWidth
                  rules={{ required: true, min: 0 }}
                  inputProps={{ min: 0 }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  }}
                />
              </>
            )}
            <FormControl>
              <CheckboxControl
                control={control}
                name="acceptDataPrivacy"
                rules={{ required: true }}
                required
                label={
                  <>
                    <Trans i18nKey="bavModule:requestEstimate.acceptDataPrivacy">
                      <a href="https://www.probonio.de/datenschutz-app" target="_blank" rel="noreferrer" />
                    </Trans>
                    *
                  </>
                }
              />
            </FormControl>
          </Stack>
        </Card>
      </Box>
      <Button type="submit" variant="contained" color="primary" fullWidth>
        {t('submitButton')}
      </Button>
    </Box>
  );
};
