import { Box, Button, Drawer, InputAdornment, Typography } from '@mui/material';
import React, { useCallback, useState, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateCouponOrderRedeem } from './CouponRedeemButton';
import { NumericInput, NumericInputProps } from 'probonio-shared-ui/component/form/NumericInput';

interface Props {
  open: boolean;
  orderAmount: number;
  remainingAmount: number;
  onMutate: (redeemConditions: UpdateCouponOrderRedeem) => void;
  onCloseDrawer: () => void;
  onResetRedeem: () => void;
}
export const CouponRedeemDrawer: React.FC<Props> = ({ open, remainingAmount, orderAmount, onMutate, onCloseDrawer, onResetRedeem }) => {
  const [redeemedAmount, setRedeemedAmount] = useState(0);
  const { t } = useTranslation('couponsModule');
  const handleFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.target.setSelectionRange(e.target.size, e.target.size);
  }, []);

  const handleBack = useCallback(
    (event: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'escapeKeyDown') {
        return;
      }
      setRedeemedAmount(0);
      onCloseDrawer();
    },
    [onCloseDrawer],
  );

  const handleSubmit = useCallback(() => {
    const newRemainingAmount = Math.round(remainingAmount - redeemedAmount * 100);
    onMutate({ newRemainingAmount, shouldBeRedeemed: newRemainingAmount ? false : true });
    setRedeemedAmount(0);
    onCloseDrawer();
  }, [remainingAmount, redeemedAmount, onMutate, onCloseDrawer]);

  const handleChange = useCallback<NonNullable<NumericInputProps['onChange']>>(
    event => {
      const value = (event.target.value as number) * 100;
      if (value <= remainingAmount) {
        setRedeemedAmount(value / 100);
      }
    },
    [remainingAmount, setRedeemedAmount],
  );

  const handleEnter = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'Enter') {
        handleSubmit();
        onCloseDrawer();
      }
    },
    [onCloseDrawer, handleSubmit],
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Drawer open={open} anchor="bottom" onClose={handleBack} PaperProps={{ sx: { maxWidth: 450, margin: 'auto' } }}>
        <Box mx={1} display="flex" flexDirection="column">
          <Typography textAlign="center" variant="h2" mb={1} mt={1.5}>
            {t('partRedeem.title')}
          </Typography>
          <Typography mb={0.5} variant="caption" color="text.secondary">
            {t('partRedeem.label')}
          </Typography>
          <NumericInput
            autoFocus={true}
            onFocus={handleFocus}
            value={redeemedAmount}
            precision={2}
            onChange={handleChange}
            InputProps={{
              onKeyDown: handleEnter,
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            inputProps={{ inputMode: 'numeric', style: { textAlign: 'right' } }}
            sx={{ marginBottom: 0.8 }}
          />

          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ mb: 1.2 }} variant="caption" color="text.secondary">
              {t('partRedeem.remainingAmount')}
            </Typography>
            <Typography sx={{ mb: 1 }} variant="caption">
              {(remainingAmount / 100 - redeemedAmount).toLocaleString(undefined, { style: 'currency', currency: 'EUR' })}
            </Typography>
          </Box>
          <Button fullWidth variant="contained" color="secondary" onClick={onResetRedeem} disabled={orderAmount === remainingAmount}>
            {t('partRedeem.reset')}
          </Button>
          <Box justifyContent="space-between" display="flex" mb={2} mt={0.5}>
            <Button variant="contained" color="secondary" onClick={handleBack} sx={{ width: '35%' }}>
              {t('common:button.abort')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ width: '35%' }} disabled={!redeemedAmount}>
              {t('common:button.save')}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};
