import { Box, Button, Grid, Typography } from '@mui/material';
import AppPromotion from '../AppPromotion';
import { BasicDialog } from '../../dialog/BasicDialog';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';

export const LOCALSTORAGE_KEY = 'appPromotionModalOpened';

export function hasSeenAppPromotion(): boolean {
  if ((window.navigator.platform === 'iPhone' && window.navigator.vendor === 'Apple Computer, Inc.') || Capacitor.isNativePlatform()) {
    return true;
  }
  return localStorage.getItem(LOCALSTORAGE_KEY) === 'opened';
}

const AppPromotionModal: React.FC = () => {
  const { t } = useTranslation('appPromotionModal');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const userSeenModalBefore = localStorage.getItem(LOCALSTORAGE_KEY);
    if (userSeenModalBefore === 'opened') {
      return;
    }

    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    localStorage.setItem(LOCALSTORAGE_KEY, 'opened');
    setIsOpen(false);
  }, []);

  if ((window.navigator.platform === 'iPhone' && window.navigator.vendor === 'Apple Computer, Inc.') || Capacitor.isNativePlatform()) {
    return <></>;
  }

  return (
    <BasicDialog
      maxWidth="xs"
      hideCloseIcon
      dialogState={{ isOpen: isOpen, handleClose: handleClose }}
      title={
        <Box my={0.5} display="flex" justifyContent="center" width="100%">
          <Typography textAlign="center" variant="h1">
            {t('title')}
          </Typography>
        </Box>
      }
      content={
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <Typography textAlign="center">{t('content')}</Typography>
          </Grid>

          <Grid item sx={{ maxWidth: '300px' }} display="flex" justifyContent="center">
            <AppPromotion iconsOnly />
          </Grid>
        </Grid>
      }
      actions={
        <Box width="100%" display="flex" justifyContent="center">
          <Box maxWidth="280px" flexGrow="1" mb={0.5}>
            <Button fullWidth variant="contained" onClick={handleClose} data-test-id="close-app-modal">
              {t('common:button.close')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

export default AppPromotionModal;
