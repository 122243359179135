import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, CircularProgress, Typography, styled, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { ConfirmationModal } from '../../component/confirmationModal';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { useSetArticleStatusMutation } from './articleStatusMutations';

const Image = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  marginBottom: theme.spacing(0.75),
  flexGrow: 1,
}));

export const ArticlePage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('benefitModule');
  const backNavigator = useBackNavigator();

  const theme = useTheme();

  const { articleId } = useParams();
  const { data, isLoading } = useEmployeeQuery(['benefits', 'news', 'articles', articleId, { withImage: true }], async context => {
    if (!articleId) {
      return;
    }
    const result = await apis.news
      .getArticleWithEmployeeStatus({
        tenantId: context.tenantId,
        employeeId: context.employeeId,
        articleId,
        withImage: true,
      })
      .then(res => res.data);
    return result;
  });

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    backNavigator.back();
  }, [backNavigator]);

  const handleOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleReadConfirmation = useCallback(() => {
    if (data?.readConfirmation && data?.articleViewStatus !== 'CONFIRMED_SEEN') {
      handleOpenDialog();
    } else {
      void backNavigator.back();
    }
  }, [backNavigator, data?.articleViewStatus, data?.readConfirmation, handleOpenDialog]);

  const article = data ? data : undefined;

  const trueDate = article && DateTime.fromISO(article.publishDate!);

  const setStatusMutation = useSetArticleStatusMutation(articleId!);

  useEffect(() => {
    if (data && !data.articleViewStatus) {
      setStatusMutation.mutate('SEEN');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleConfirm = useCallback(() => {
    setStatusMutation.mutate('CONFIRMED_SEEN');

    if (isOpen) {
      handleCancel();
    }
  }, [handleCancel, isOpen, setStatusMutation]);

  return (
    <MobileLayout
      paperBackground
      header={
        <AppToolbar
          absolute={!!article?.imageURL}
          invert
          backButton={<BackIconButton onClick={handleReadConfirmation} color="inherit" />}
        />
      }
    >
      {isLoading || !article ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ backgroundColor: theme.palette.background.paper, height: '100%' }} display="flex" flexDirection={'column'} flexGrow={1}>
          <Box width="100%" display="flex" flexDirection="column">
            {article.imageURL && <Image height={250} src={article.imageURL} />}
            {data?.readConfirmation && data?.articleViewStatus !== 'CONFIRMED_SEEN' && (
              <>
                <LoadingButton
                  onClick={handleConfirm}
                  sx={{ marginLeft: 1, marginRight: 1, marginBottom: 1 }}
                  color="primary"
                  variant="outlined"
                  data-test-id="read-confirmation-button"
                  loading={setStatusMutation.isPending}
                >
                  <InfoOutlinedIcon sx={{ marginRight: theme.spacing(0) }} />
                  <p style={{ fontSize: 14, margin: '0' }}>{t('benefit.NEWS.buttonText')}</p>
                </LoadingButton>
              </>
            )}
            <Typography variant="paragraph" pl={1} data-test-id="releaseDate">
              {trueDate?.toLocaleString()}
            </Typography>
          </Box>
          <Box p={0.5} flexGrow={1} px={1}>
            <Typography variant="h2" data-test-id="articleTitle" mb={0.75}>
              {article.title}
            </Typography>
            <Typography
              data-test-id="articleDescription"
              color="text.secondary"
              variant="body1"
              dangerouslySetInnerHTML={{ __html: article.description || '' }}
              gutterBottom
              component="div"
              sx={{ '& p': { marginTop: 0 } }}
            />
          </Box>
          <ConfirmationModal
            onCancel={handleCancel}
            onSubmit={handleConfirm}
            isOpen={isOpen}
            message={t('benefit.NEWS.readConfirmation.confirmMessage')}
            title={t('benefit.NEWS.readConfirmation.title')}
            cancelButtonTitle={t('benefit.NEWS.readConfirmation.cancel')}
          />
          <TenantLogo sx={{ textAlign: 'center', marginBottom: 1, paddingBottom: 1, px: 1, py: 1 }} />
        </Box>
      )}
    </MobileLayout>
  );
};
