import { Box, Card, CardActionArea, CardContent, CardMedia, Chip, Typography } from '@mui/material';
import { NewsArticleDTO } from 'probonio-shared-ui/api';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

interface NewsCardProps {
  article: NewsArticleDTO;
}

export const NewsCard: React.FC<NewsCardProps> = ({ article }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('benefitModule');
  const handleClick = useCallback(() => {
    navigate(`article/${article.id}`);
  }, [article, navigate]);

  const trueDate = DateTime.fromISO(article.publishDate!);

  const isUnread = article.readConfirmation ? article.articleViewStatus !== 'CONFIRMED_SEEN' : !article.articleViewStatus;
  return (
    <Card sx={{ marginBottom: 1 }} elevation={1} data-test-id="news-article-card">
      <CardActionArea onClick={handleClick}>
        {article.imageURL && <CardMedia src={article.imageURL} title={article.title} height={150} component="img" />}
        <CardContent>
          <Box mb={0.5}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={0.75}>
              <Typography variant="h2" data-test-id={article.title} noWrap>
                {article.title}
              </Typography>{' '}
              {isUnread && (
                <Chip
                  data-test-id="unread-badge"
                  size="small"
                  color="error"
                  variant="outlined"
                  component={'div'}
                  label={t('benefit.NEWS.unread')}
                />
              )}
            </Box>
            <Typography
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                maxHeight: theme => theme.spacing(5),
                '& p': { marginTop: 0 },
              }}
              dangerouslySetInnerHTML={{ __html: article.description || '' }}
              mb={0.5}
            />
          </Box>
          <Box textAlign="right">
            <Typography variant="paragraph">{trueDate.toLocaleString()}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
