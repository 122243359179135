import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PercentIcon from '@mui/icons-material/Percent';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import confetti from 'canvas-confetti';
import { CopyToClipboardButton } from 'probonio-shared-ui/component/button/CopyToClipboardButton';
import { apis } from 'probonio-shared-ui/module/api';
import { loadMe } from 'probonio-shared-ui/module/me';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fontWeights } from '../../theme';
import { BasicDialog, DialogState } from '../dialog/BasicDialog';

interface Props {
  dialogState: DialogState;
  dialogDescription?: string;
}

export const ReferralModal: React.FC<Props> = ({ dialogState, dialogDescription }) => {
  const { t } = useTranslation('referralCodeModal');
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.me.user);
  const theme = useTheme();

  useEffect(() => {
    if (dialogState.isOpen) {
      void confetti({
        particleCount: 300,
        spread: 360,
        scalar: 2,
        zIndex: 1301,
        decay: 0.9,
        origin: { x: 0.5, y: 0.15 },
        startVelocity: 20,
        disableForReducedMotion: true,
      });
      setTimeout(() => confetti.reset(), 5500);
    }
  }, [dialogState.isOpen]);

  const mutation = useMutation({
    mutationFn: async () => {
      await apis.user.createReferralCode({ userId: currentUser!.id });
    },

    onSuccess: async () => {
      await dispatch(loadMe(true));
    },
  });

  function formatRegistrationCode(code?: string): string | undefined {
    if (code) {
      const matches = /(\w{4})(\w{4})(\w{4})?/.exec(code);
      return matches ? `${matches[1]}-${matches[2]}${matches[3] ? '-' : ''}${matches[3] || ''}` : undefined;
    }
  }

  useEffect(() => {
    if (dialogState.isOpen && !currentUser?.referralCode) {
      mutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.referralCode, dialogState.isOpen]);

  return (
    <BasicDialog
      dataTestId="referral-modal"
      dialogState={dialogState}
      maxWidth="xs"
      fullWidth
      title={t('dialogTitle')}
      content={
        <Grid pt={0.5} gap={0.3} display="flex" flexDirection="column">
          <HandshakeIcon sx={{ alignSelf: 'center', height: 50, width: 50, color: theme.palette.primary.main }} />
          <Typography variant="button" fontSize={20} fontWeight={fontWeights.bold} textAlign="center" pt={0.2} pb={0.2}>
            {t('howItWorks')}
          </Typography>
          <Typography variant="descriptionBold" color="text.secondary" textAlign={'center'}>
            {dialogDescription || t('dialogDescription')}
          </Typography>
          <List sx={{ alignSelf: 'center', maxWidth: '260px' }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <CardGiftcardIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h2">{t('bonusOneTitle')}</Typography>}
                secondary={<Typography variant="subtitle2">{t('bonusOneDesc')}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <PercentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h2">{t('bonusTwoTitle')}</Typography>}
                secondary={<Typography variant="subtitle2">{t('bonusTwoDesc')}</Typography>}
              />
            </ListItem>
          </List>
          <Typography variant="descriptionBold" color="text.secondary" textAlign={'center'} pb={0.5}>
            {t('dialogHowTo')}
          </Typography>
          <TextField
            data-test-id="referral-code-realcode"
            variant="outlined"
            fullWidth
            label={t('code')}
            value={formatRegistrationCode(currentUser?.referralCode) || ''}
            InputProps={{
              readOnly: true,
              endAdornment: <CopyToClipboardButton edge="end" value={formatRegistrationCode(currentUser?.referralCode)} />,
            }}
          />
          <Typography variant="descriptionBold" color="text.secondary" textAlign="center">
            {t('dialogFooter')}
          </Typography>
        </Grid>
      }
    />
  );
};
