import { Capacitor } from '@capacitor/core';
import BugReportIcon from '@mui/icons-material/BugReport';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Box, Button, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useLoginContext } from '@tiffinger-thiel/appauth-react';
import { useSnackbar } from 'notistack';
import { apis } from 'probonio-shared-ui/module/api';
import { loadMe, useHasAvailableTenants } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LetterAvatar } from '../component/avatar';
import { BackIconButton } from '../component/button/BackIconButton';
import { EditIconButton } from '../component/button/EditIconButton';
import { LinkIconButton } from '../component/button/LinkIconButton';
import NavigationButton from '../component/button/NavigationButton';
import { useNewDialogState } from '../component/dialog/BasicDialog';
import { SelectLocale } from '../component/languageSelect/SelectLocale';
import { MobileLayout } from '../component/layout';
import { AppToolbar } from '../component/layout/AppToolbar';
import { VerticalBox } from '../component/layout/BoxLayout';
import { APP_VERSION, isDevelopment } from '../constants';
import i18n, { LocaleEnum } from '../lang';
import { redirectToPasswordReset } from '../module/auth/passwordReset';
import MeInfoCard from '../module/me/MeInfoCard';
import { SwitchTenantDialog } from '../module/me/SwitchTenantDialog';
import { useBackNavigator } from '../module/navigation/BackNavigator';
import { NotificationSwitch } from '../module/profile/EnableDisableNotifications';
import { ReferralDialogButton } from '../module/profile/ReferralDialogButton';
import { ThemeSwitcher } from '../module/theme/ThemeSwitcher';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

const ProfilePage: React.FC = () => {
  const { t } = useTranslation('profilePage');
  const { token } = useLoginContext();
  const currentUser = useAppSelector(state => state.me.user);
  const backNavigator = useBackNavigator();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleLogout = useCallback(() => {
    navigate('/logout', { replace: true });
  }, [navigate]);

  const handleGoEditProfile = useCallback(() => {
    navigate('/profile/edit');
  }, [navigate]);

  const handleGoDebug = useCallback(() => {
    navigate('/debug');
  }, [navigate]);

  const handleGoToTerms = useCallback(() => {
    navigate('/terms');
  }, [navigate]);

  const mutation = useMutation({
    mutationFn: (locale: LocaleEnum) => apis.user.updateUser({ userId: currentUser!.id, updateUserDTO: { locale } }),
    onSuccess: async () => {
      await dispatch(loadMe(true));
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
  });

  const changeLanguage = useCallback(
    (event: SelectChangeEvent) => {
      void i18n.changeLanguage(event.target.value);
      mutation.mutate(event.target.value as LocaleEnum);
    },
    [mutation],
  );

  const handleCopyToken = useCallback(() => {
    // On desktop copy the token, on mobile navigate to /debug
    // as there is no other way to get to it in mobile.
    if (!Capacitor.isNativePlatform()) {
      void navigator.clipboard.writeText(token || '');
      enqueueSnackbar('token copied to clipboard');
    } else {
      navigate('/debug');
    }
  }, [token, enqueueSnackbar, navigate]);

  const hasAvailableTenants = useHasAvailableTenants(true);
  const switchTenantDialog = useNewDialogState();

  return (
    <MobileLayout
      header={
        <AppToolbar
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <>
              {isDevelopment && (
                <LinkIconButton onClick={handleGoDebug}>
                  <BugReportIcon />
                </LinkIconButton>
              )}
              {isDevelopment && (
                <LinkIconButton onClick={handleCopyToken}>
                  <ContentPasteIcon />
                </LinkIconButton>
              )}
              <EditIconButton onClick={handleGoEditProfile} />
            </>
          }
        />
      }
    >
      <Box
        paddingX={1}
        marginBottom={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          flexShrink: 0,
        }}
      >
        <Stack direction="column" spacing={1} marginBottom={0.5} flexShrink={0}>
          <Box sx={{ marginX: 1, alignSelf: 'center' }}>
            <LetterAvatar firstName={currentUser?.firstName} lastName={currentUser?.lastName} mail={currentUser?.mail} size={150} />
          </Box>

          <MeInfoCard me={currentUser} />
          {currentUser && (
            <Stack spacing={1}>
              <Box>
                <SelectLocale variant="filled" size="medium" onLanguageChange={changeLanguage} />
              </Box>
              <ThemeSwitcher />
              <Box />
            </Stack>
          )}
          <NotificationSwitch />
          <ReferralDialogButton />
          <NavigationButton onClick={redirectToPasswordReset}>
            <Typography variant="caption" className="selectable" color="text.secondary">
              {t('passwordReset')}
            </Typography>
          </NavigationButton>
          {hasAvailableTenants && (
            <NavigationButton onClick={switchTenantDialog.handleOpen}>
              <Typography variant="caption" className="selectable" color="text.secondary">
                {t('switchTenant')}
              </Typography>
            </NavigationButton>
          )}
        </Stack>
        <Button sx={{ mt: 0.5 }} data-test-id="to-terms" color="secondary" variant="contained" onClick={handleGoToTerms}>
          {t('legalTerms:longTitle')}
        </Button>
        <Grid display="flex" gap={1.5} sx={{ marginTop: 1 }}>
          <Button fullWidth color="secondary" variant="contained" href="https://probonio.de/impressum">
            {t('imprint')}
          </Button>
          <Button fullWidth color="secondary" variant="contained" href="https://probonio.de/datenschutz-app">
            {t('privacy')}
          </Button>
        </Grid>
        <VerticalBox sx={{ flexShrink: 0 }}>
          <Button sx={{ marginBottom: 2, marginTop: 1 }} variant="outlined" onClick={handleLogout}>
            {t('logout')}
          </Button>
        </VerticalBox>
        <VerticalBox sx={{ flexShrink: 0 }}>{t('common:appVersion', { version: APP_VERSION })}</VerticalBox>
      </Box>
      <SwitchTenantDialog dialogState={switchTenantDialog.dialogState} />
    </MobileLayout>
  );
};

export default ProfilePage;
