import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useTranslation } from 'react-i18next';
import { useAvailableCoupons } from '../../module/coupons';
import { getCouponImageUrl } from '../couponsShared/checkCouponImageUrl';

export const AutomaticSelection: React.FC = () => {
  const { t } = useTranslation('couponsModule');
  const theme = useTheme();
  const { tenant } = useTenant();
  const { defaultCoupon, couponsQuery } = useAvailableCoupons();
  const { url: couponImageUrl } = getCouponImageUrl(defaultCoupon);

  const couponOrderDeadline = tenant?.couponOrderDeadline || 31;
  let deadlineKey = 'coupons.textAutomaticSelectionInfo';
  if (couponOrderDeadline === 1) {
    deadlineKey = 'coupons.textAutomaticSelectionInfoFirst';
  } else if (couponOrderDeadline === 31) {
    deadlineKey = 'coupons.textAutomaticSelectionInfoLast';
  }

  const isTenantDefault = couponsQuery.data?.details.isTenantDefault;

  return (
    <Stack spacing={0.5}>
      <Grid container paddingX={1.5} alignItems="center">
        <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
          <img src={couponImageUrl} style={{ borderRadius: 8, maxHeight: 50, maxWidth: '100%' }} />
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ fontWeight: 'bold', height: '20' }}>
            {t(
              isTenantDefault
                ? 'exploreCoupons.details.tenantDefaultSelectedMessage'
                : 'exploreCoupons.details.defaultCouponSelectedMessage',
            )}
          </Box>
          <Typography>{t('coupons.defaultCouponText', { couponName: defaultCoupon?.name })}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ paddingX: theme.spacing(1), textAlign: 'center' }}>
        <Typography sx={{ textAlign: 'center' }} variant="description" color="text.secondary">
          {t(deadlineKey, { couponOrderDeadline })}
        </Typography>
      </Grid>
    </Stack>
  );
};
