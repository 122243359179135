import { Box, Grid, LinearProgress, linearProgressClasses, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';

interface Props {
  maxRefund?: number;
  taxFreeRefund: number | undefined;
  taxedRefund: number | undefined;
  benefit: BenefitDTOBenefitEnum;
}

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.greyBackground,

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
  [`& .${linearProgressClasses.bar2Buffer}`]: {
    backgroundColor: theme.palette.primary.main,
    opacity: '50%',
  },
  '& .MuiLinearProgress-dashed': {
    backgroundImage: 'none',
    animation: 'none',
  },
}));

export const TaxProgressMoney: React.FC<Props> = ({ maxRefund, taxFreeRefund = 0, taxedRefund = 0, benefit }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const refund = taxFreeRefund + taxedRefund;
  return (
    <Box marginX={2}>
      <Box display="flex" justifyContent="space-between" marginBottom={0.3}>
        <Typography variant="h2">{t('common:money', { money: refund })}</Typography>
        <Typography variant="paragraph">{t(`benefit.${benefit}.refundProgress.title`)}</Typography>
        <Typography variant="h2">{t('common:money', { money: maxRefund })} </Typography>
      </Box>

      <StyledLinearProgress
        variant="buffer"
        valueBuffer={refund && maxRefund ? (refund / maxRefund) * 100 : 0}
        value={taxedRefund && maxRefund ? (taxedRefund / maxRefund) * 100 : 0}
      />
      <Grid container marginTop={0.5}>
        <Grid display="flex" item xs={4} gap={0.2}>
          <CircleIcon fontSize="small" color="primary" />
          <Typography variant="paragraph">{t(`benefit.${benefit}.refundProgress.progress`)}</Typography>
        </Grid>
        <Grid display="flex" item xs={4} gap={0.2} position="relative">
          <CircleIcon fontSize="small" htmlColor={theme.palette.datePicker.border} sx={{ position: 'absolute' }} />
          <CircleIcon fontSize="small" htmlColor={theme.palette.primary.main} sx={{ opacity: '50%' }} />
          <Typography variant="paragraph">{t(`benefit.${benefit}.refundProgress.taxFreeProgress`)}</Typography>
        </Grid>
        <Grid display="flex" item xs={4} gap={0.2}>
          <CircleIcon fontSize="small" htmlColor={theme.palette.greyBackground} />
          <Typography variant="paragraph">{t(`benefit.${benefit}.refundProgress.total`)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
