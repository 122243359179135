import { Grid, styled } from '@mui/material';

const CUTOUT_SIZE = 20;

export const CONTENT_SIZE = 35;

export const CouponItemBackground = styled(Grid)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  alignItems: 'center',
  marginBottom: theme.spacing(0.5),
  '&::before': {
    content: '""',
    left: -CUTOUT_SIZE / 2,
    position: 'absolute',
    width: CUTOUT_SIZE,
    height: CUTOUT_SIZE,
    borderRadius: '50%',
    backgroundColor: theme.palette.background.default,
  },
  '&::after': {
    content: '""',
    right: -CUTOUT_SIZE / 2,
    position: 'absolute',
    width: CUTOUT_SIZE,
    height: CUTOUT_SIZE,
    borderRadius: '50%',
    backgroundColor: theme.palette.background.default,
  },
  '.vLine-dashed': {
    borderLeft: '2px dashed lightgrey',
    height: CONTENT_SIZE + 20,
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(-0.5),
    borderColor: theme.palette.primary.main,
  },
  '.hLine-dashed': {
    borderLeft: '2px dashed lightgrey',
    height: CONTENT_SIZE + 20,
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(-0.5),
    borderColor: theme.palette.primary.contrastText,
  },
  '.couponEnd': {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  '.PENDING': {
    color: theme.palette.primary.main,
  },
  '.ORDERED': {
    color: theme.palette.primary.main,
  },
  '.PAYMENT_DUE': {
    color: theme.palette.primary.main,
  },
  '.ERROR': {
    color: theme.palette.error.main,
  },
  '.PAYMENT_FAILED': {
    color: theme.palette.error.main,
  },
  '.icon': {
    maxHeight: CONTENT_SIZE,
    maxWidth: 50,
    borderRadius: 5,
  },
}));
