import CheckIcon from '@mui/icons-material/Check';
import { Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UserDTO, UserDTOLegalTermsActionEnum } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import React, { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { sessionStorageSkippedKey } from './legalTerms.const';
import { loadMe } from 'probonio-shared-ui/module/me';
import { useAppDispatch } from '../../redux/hooks';
interface Props {
  user: UserDTO;
  tenantId: string;
  action: UserDTOLegalTermsActionEnum;
  align?: 'center' | 'left';
}

export const LegalTermsInfo: React.FC<Props> = ({ tenantId, user, action, align = 'left' }) => {
  const withMessage = useWithMessage();

  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  }, []);

  const { data: latestTerms } = useQuery({
    queryKey: ['legalTerms', apis.legalTerms.getLatestLegalTerms.name],
    queryFn: () =>
      apis.legalTerms
        .getLatestLegalTerms({ legalType: 'USER_TERMS' })
        .then(res => res.data)
        .catch(e => {
          if (e instanceof AxiosError && e.response?.status === 404) {
            // swallow if no terms yet
          } else {
            withMessage(e);
          }
          return null;
        }),
  });

  const consent = useMutation({
    mutationFn: async () => {
      await apis.legalTerms.createLegalTermsConsentLog({
        tenantId,
        createLegalTermsConsentLogDTO: { legalTermsId: latestTerms!.id },
      });
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['me'] });
      await dispatch(loadMe(true));
    },
  });

  const handleConsent = useCallback(() => {
    consent.mutate();
  }, [consent]);

  const handleSkip = useCallback(() => {
    sessionStorage.setItem(sessionStorageSkippedKey, user.id);
    void queryClient.invalidateQueries({ queryKey: ['me'] });
    navigate(0);
  }, [navigate, queryClient, user.id]);

  return (
    <Stack textAlign={align}>
      <Typography textAlign={align} data-test-id="legal-terms-version">
        {latestTerms ? (
          <>
            <Trans i18nKey="legalTerms:version" values={{ version: latestTerms.version }}>
              <a href={latestTerms.fileUrl} target="_blank" rel="noreferrer" />
            </Trans>
          </>
        ) : (
          <Trans i18nKey="legalTerms:noTerms" />
        )}
      </Typography>
      <Stack justifyContent={align}>
        {action === UserDTOLegalTermsActionEnum.None && user.legalTermsUserLastConsentDate && (
          <Typography marginTop={1} component="span" textAlign={align} data-test-id="legal-terms-consent-done">
            <CheckIcon color="success" fontSize="small" style={{ verticalAlign: 'middle', marginBottom: '3px', marginRight: 5 }} />
            <Trans i18nKey="legalTerms:actions.consentDone" values={{ lastDate: new Date(user.legalTermsUserLastConsentDate) }} />
          </Typography>
        )}
        {latestTerms && action !== UserDTOLegalTermsActionEnum.None && (
          <Stack alignItems={align}>
            <FormControlLabel
              sx={{ paddingTop: 1 }}
              control={<Checkbox checked={isChecked} data-test-id="legal-terms-chbx" onChange={handleCheckboxChange} />}
              label={
                <Typography component="span">
                  <Trans i18nKey="legalTerms:actions.checkLabel">
                    <a href={latestTerms.fileUrl!} target="_blank" rel="noreferrer" />
                  </Trans>
                </Typography>
              }
            />
            <Grid container justifyContent="center" display="flex" gap={1} marginTop={2}>
              {action === UserDTOLegalTermsActionEnum.Skip && (
                <Button variant="outlined" data-test-id="legal-terms-skip" size="small" onClick={handleSkip}>
                  <Typography fontWeight="bold">
                    <Trans i18nKey="legalTerms:actions.skip" />
                  </Typography>
                </Button>
              )}
              <LoadingButton
                size="small"
                type="submit"
                variant="contained"
                dataTestId="legal-terms-accept"
                onClick={handleConsent}
                disabled={!isChecked}
              >
                <Typography fontWeight="bold">
                  <Trans i18nKey="legalTerms:actions.accept" />
                </Typography>
              </LoadingButton>
            </Grid>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
