import EmailIcon from '@mui/icons-material/Email';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Avatar, Box, Button, Grid, styled, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../component/button/BackIconButton';
import { MobileLayout } from '../component/layout';
import { AppToolbar } from '../component/layout/AppToolbar';
import { PROBONIO_MAIL, PROBONIO_MANUAL, PROBONIO_TEL } from '../constants';
import MagicCounter from '../module/magicCounter/MagicCounter';
import { useBackNavigator } from '../module/navigation/BackNavigator';
import support1 from './support1.jpeg';
import support2 from './support2.jpeg';
import support3 from './support3.jpeg';

const SupportAvatar = styled(Avatar)(({ theme }) => ({
  width: 94,
  height: 94,
  padding: 4,
  backgroundColor: '#fff',
  marginLeft: -20,
  '>img': {
    borderRadius: '100%',
  },
}));

const SupportButton = styled(Button)(({ theme }) => ({
  maxWidth: 240,
  width: '100%',
  justifyContent: 'flex-start',
}));

const SupportButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const BenefitConsultingPage: React.FC = () => {
  const { t } = useTranslation('benefitConsultingPage');
  const backNavigator = useBackNavigator();
  const [modalOpen, setModalOpen] = useState(false);
  const [tabCounter, setTabCounter] = useState(0);
  const navigate = useNavigate();
  const handleEasterEggClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleTab = useCallback(() => {
    if (tabCounter === 3) {
      setModalOpen(true);
      setTabCounter(0);
    } else {
      setTabCounter(tabCounter + 1);
    }
  }, [tabCounter]);

  const handleGoFeedback = useCallback(() => {
    navigate('/feedback');
  }, [navigate]);

  return (
    <MobileLayout header={<AppToolbar title={t('title')} backButton={<BackIconButton onClick={backNavigator.back} />} />}>
      <Box padding={2}>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: 1 }}>
          <SupportAvatar src={support1} />
          <SupportAvatar src={support2} onClick={handleTab} />
          <SupportAvatar src={support3} />
        </Box>
        <Box paddingTop={2}>
          <Typography variant="body1">{t('description')}</Typography>
        </Box>
        <Grid paddingTop={2} container spacing={0.5}>
          <SupportButtonGrid sx={{ marginBottom: 1 }} item xs={12}>
            <SupportButton variant="contained" href={PROBONIO_MANUAL} startIcon={<MenuBookIcon />}>
              {t('manual')}
            </SupportButton>
          </SupportButtonGrid>
          <SupportButtonGrid item xs={12}>
            <SupportButton variant="contained" href="https://wa.me/4915733775777" startIcon={<WhatsAppIcon />}>
              {t('whatsApp')}
            </SupportButton>
          </SupportButtonGrid>
          <SupportButtonGrid item xs={12}>
            <SupportButton variant="contained" onClick={handleGoFeedback} startIcon={<FeedbackIcon />}>
              {t('profilePage:feedback.button')}
            </SupportButton>
          </SupportButtonGrid>
          <SupportButtonGrid item xs={12}>
            <SupportButton variant="contained" href={`mailto:${PROBONIO_MAIL}`} startIcon={<EmailIcon />}>
              {t('mail')}
            </SupportButton>
          </SupportButtonGrid>
          <SupportButtonGrid item xs={12}>
            <SupportButton variant="contained" href={`tel:${PROBONIO_TEL.replace(/\s/g, '')}`} startIcon={<PhoneIcon />}>
              {PROBONIO_TEL}
            </SupportButton>
          </SupportButtonGrid>
        </Grid>
      </Box>
      <MagicCounter open={modalOpen} handleCloseCounter={handleEasterEggClose} />
    </MobileLayout>
  );
};

export default BenefitConsultingPage;
