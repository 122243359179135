import React from 'react';
import { Alert, AlertPalette, AlertProps, Typography, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props extends AlertProps {}

const defaultIconMapping = {
  success: <CheckCircleOutlineIcon fontSize="medium" />,
  warning: <WarningAmberOutlinedIcon fontSize="medium" />,
  error: <ErrorOutlineOutlinedIcon fontSize="medium" />,
  info: <InfoOutlinedIcon fontSize="medium" />,
};

export const BasicAlert: React.FC<Props> = ({
  color,
  children,
  variant = 'standard',
  icon,
  iconMapping = defaultIconMapping,
  severity = 'success',
  ...alertProps
}) => {
  const theme = useTheme();

  if (variant === 'standard' && (color || severity)) {
    const colorPalette: AlertPalette = theme.palette.alert[color || severity] as AlertPalette;
    let chosenIcon: React.ReactNode = icon || iconMapping[severity] || defaultIconMapping[severity];

    // In the original Alert false removes the icon completely
    if (icon === false) {
      chosenIcon = false;
    }

    return (
      <Alert
        icon={
          chosenIcon && (
            <Typography
              component="span"
              sx={{
                color: colorPalette.icon,
                padding: 0,
                margin: 0,
                // Somehow the icon has a different height than the original alert icons -> hard code it to the same height, the svg has.
                height: '1em',
              }}
            >
              {chosenIcon}
            </Typography>
          )
        }
        variant="standard"
        severity={severity}
        {...alertProps}
        sx={{
          color: colorPalette.text,
          backgroundColor: colorPalette.background,
          fontSize: theme.typography.body1,
          ...alertProps.sx,
        }}
      >
        {children}
      </Alert>
    );
  }

  return (
    <Alert variant={variant} color={color} severity={severity} icon={icon} iconMapping={iconMapping} {...alertProps}>
      {children}
    </Alert>
  );
};
