import { useTheme } from '@mui/material';
import { MobileInfoPage } from 'probonio-shared-ui/component/infoPage';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { TenantLogo } from '../../component/layout/TenantLogo';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

export const CustomBenefitInfoPage: React.FC = () => {
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const { t } = useTranslation('benefitModule');

  const { customBenefitId } = useParams();

  const { data: customBenefit } = useTenantQuery(
    ['benefits', 'custom', customBenefitId, apis.customBenefits.getCustomBenefit.name],
    tenantId => apis.customBenefits.getCustomBenefit({ tenantId, customBenefitId: customBenefitId! }).then(resp => resp.data),
    { enabled: !!customBenefitId },
  );

  return (
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.paper}
      header={
        <AppToolbar
          title={t('benefit.CUSTOM.name', { title: customBenefit?.title || '' })}
          backButton={<BackIconButton onClick={backNavigator.back} />}
        />
      }
    >
      <MobileInfoPage image={customBenefit?.tileImageURL} footer={<TenantLogo sx={{ width: '80%', textAlign: 'center' }} />}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: customBenefit?.infoHtml || '' }} />
      </MobileInfoPage>
    </MobileLayout>
  );
};
