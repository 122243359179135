/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CostCentersDTO } from '../dto';
// @ts-ignore
import { CreateTenantDTO } from '../dto';
// @ts-ignore
import { CreateTestTenantDTO } from '../dto';
// @ts-ignore
import { DepartmentsDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { NotifyTenantExportTypeDTO } from '../dto';
// @ts-ignore
import { TenantDTO } from '../dto';
// @ts-ignore
import { TenantExportHistoryWithUserListDTO } from '../dto';
// @ts-ignore
import { TenantRegistrationCodeDTO } from '../dto';
// @ts-ignore
import { TenantSalesMailDTO } from '../dto';
// @ts-ignore
import { TenantsDTO } from '../dto';
// @ts-ignore
import { UpdateTenantDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create or reset tenant registration code
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegistrationCode: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createRegistrationCode', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/registrationCode`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a new user and invites a tenant admin
         * @param {CreateTenantDTO} createTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (createTenantDTO: CreateTenantDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTenantDTO' is not null or undefined
            assertParamExists('createTenant', 'createTenantDTO', createTenantDTO)
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTenantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a new user and invites a tenant admin for a test account
         * @param {CreateTestTenantDTO} createTestTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestTenant: async (createTestTenantDTO: CreateTestTenantDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTestTenantDTO' is not null or undefined
            assertParamExists('createTestTenant', 'createTestTenantDTO', createTestTenantDTO)
            const localVarPath = `/tenants/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTestTenantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a form POST upload link for uploading the tenant image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createUploadLink', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/createUploadLink`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete the tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteTenant', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a tenant
         * @param {string} tenantId 
         * @param {boolean} [withImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: async (tenantId: string, withImage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenant', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (withImage !== undefined) {
                localVarQueryParameter['withImage'] = withImage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this will get the export history and count it entries
         * @summary get tenant export history
         * @param {string} tenantId 
         * @param {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'>} [benefits] Return entries containing all given benefits
         * @param {string} [searchText] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantExportHistory: async (tenantId: string, benefits?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'>, searchText?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantExportHistory', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/exportHistory`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (benefits) {
                localVarQueryParameter['benefits'] = benefits;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list costCenters
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCostCenters: async (tenantId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listCostCenters', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/costCenters`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list departments
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDepartments: async (tenantId: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('listDepartments', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/departments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list tenants
         * @param {boolean} [hasTenantBilling] false to filter for tenants without a tenant billing configured yet, true to only get tenants which have some configured
         * @param {boolean} [includeEmployeeCount] true to include the count of (non-deleted) employees, default is false
         * @param {boolean} [includeBillingType] true to include the currently active billing type
         * @param {'MAX_PRICE' | 'STATIC' | 'PER_BENEFIT' | 'FREE' | 'MAX_PRICE_BENEFIT_ACTIVATION'} [billingType] 
         * @param {string} [filter] 
         * @param {Array<string>} [tenantIds] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenants: async (hasTenantBilling?: boolean, includeEmployeeCount?: boolean, includeBillingType?: boolean, billingType?: 'MAX_PRICE' | 'STATIC' | 'PER_BENEFIT' | 'FREE' | 'MAX_PRICE_BENEFIT_ACTIVATION', filter?: string, tenantIds?: Array<string>, page?: number, pageSize?: number, sortBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (hasTenantBilling !== undefined) {
                localVarQueryParameter['hasTenantBilling'] = hasTenantBilling;
            }

            if (includeEmployeeCount !== undefined) {
                localVarQueryParameter['includeEmployeeCount'] = includeEmployeeCount;
            }

            if (includeBillingType !== undefined) {
                localVarQueryParameter['includeBillingType'] = includeBillingType;
            }

            if (billingType !== undefined) {
                localVarQueryParameter['billingType'] = billingType;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (tenantIds) {
                localVarQueryParameter['tenantIds'] = tenantIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {NotifyTenantExportTypeDTO} notifyTenantExportTypeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyTestAccountExportTypeChange: async (tenantId: string, notifyTenantExportTypeDTO: NotifyTenantExportTypeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('notifyTestAccountExportTypeChange', 'tenantId', tenantId)
            // verify required parameter 'notifyTenantExportTypeDTO' is not null or undefined
            assertParamExists('notifyTestAccountExportTypeChange', 'notifyTenantExportTypeDTO', notifyTenantExportTypeDTO)
            const localVarPath = `/tenants/{tenantId}/notifyTestAccountExportTypeChange`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notifyTenantExportTypeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create mail to send to sales
         * @param {string} tenantId 
         * @param {TenantSalesMailDTO} tenantSalesMailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSalesMail: async (tenantId: string, tenantSalesMailDTO: TenantSalesMailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('sendSalesMail', 'tenantId', tenantId)
            // verify required parameter 'tenantSalesMailDTO' is not null or undefined
            assertParamExists('sendSalesMail', 'tenantSalesMailDTO', tenantSalesMailDTO)
            const localVarPath = `/tenants/{tenantId}/sendSalesMail`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantSalesMailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the tenant
         * @param {string} tenantId 
         * @param {UpdateTenantDTO} updateTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: async (tenantId: string, updateTenantDTO: UpdateTenantDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateTenant', 'tenantId', tenantId)
            // verify required parameter 'updateTenantDTO' is not null or undefined
            assertParamExists('updateTenant', 'updateTenantDTO', updateTenantDTO)
            const localVarPath = `/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTenantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create or reset tenant registration code
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRegistrationCode(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantRegistrationCodeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRegistrationCode(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a new user and invites a tenant admin
         * @param {CreateTenantDTO} createTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(createTenantDTO: CreateTenantDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenant(createTenantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a new user and invites a tenant admin for a test account
         * @param {CreateTestTenantDTO} createTestTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestTenant(createTestTenantDTO: CreateTestTenantDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestTenant(createTestTenantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a form POST upload link for uploading the tenant image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadLink(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadLink(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete the tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenant(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTenant(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a tenant
         * @param {string} tenantId 
         * @param {boolean} [withImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenant(tenantId: string, withImage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenant(tenantId, withImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this will get the export history and count it entries
         * @summary get tenant export history
         * @param {string} tenantId 
         * @param {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'>} [benefits] Return entries containing all given benefits
         * @param {string} [searchText] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantExportHistory(tenantId: string, benefits?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'>, searchText?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantExportHistoryWithUserListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantExportHistory(tenantId, benefits, searchText, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list costCenters
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCostCenters(tenantId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostCentersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCostCenters(tenantId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list departments
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDepartments(tenantId: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDepartments(tenantId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list tenants
         * @param {boolean} [hasTenantBilling] false to filter for tenants without a tenant billing configured yet, true to only get tenants which have some configured
         * @param {boolean} [includeEmployeeCount] true to include the count of (non-deleted) employees, default is false
         * @param {boolean} [includeBillingType] true to include the currently active billing type
         * @param {'MAX_PRICE' | 'STATIC' | 'PER_BENEFIT' | 'FREE' | 'MAX_PRICE_BENEFIT_ACTIVATION'} [billingType] 
         * @param {string} [filter] 
         * @param {Array<string>} [tenantIds] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTenants(hasTenantBilling?: boolean, includeEmployeeCount?: boolean, includeBillingType?: boolean, billingType?: 'MAX_PRICE' | 'STATIC' | 'PER_BENEFIT' | 'FREE' | 'MAX_PRICE_BENEFIT_ACTIVATION', filter?: string, tenantIds?: Array<string>, page?: number, pageSize?: number, sortBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTenants(hasTenantBilling, includeEmployeeCount, includeBillingType, billingType, filter, tenantIds, page, pageSize, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {NotifyTenantExportTypeDTO} notifyTenantExportTypeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyTestAccountExportTypeChange(tenantId: string, notifyTenantExportTypeDTO: NotifyTenantExportTypeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyTestAccountExportTypeChange(tenantId, notifyTenantExportTypeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create mail to send to sales
         * @param {string} tenantId 
         * @param {TenantSalesMailDTO} tenantSalesMailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSalesMail(tenantId: string, tenantSalesMailDTO: TenantSalesMailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSalesMail(tenantId, tenantSalesMailDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the tenant
         * @param {string} tenantId 
         * @param {UpdateTenantDTO} updateTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenant(tenantId: string, updateTenantDTO: UpdateTenantDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenant(tenantId, updateTenantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @summary create or reset tenant registration code
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegistrationCode(tenantId: string, options?: any): AxiosPromise<TenantRegistrationCodeDTO> {
            return localVarFp.createRegistrationCode(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a new user and invites a tenant admin
         * @param {CreateTenantDTO} createTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(createTenantDTO: CreateTenantDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createTenant(createTenantDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a new user and invites a tenant admin for a test account
         * @param {CreateTestTenantDTO} createTestTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestTenant(createTestTenantDTO: CreateTestTenantDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createTestTenant(createTestTenantDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a form POST upload link for uploading the tenant image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink(tenantId: string, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createUploadLink(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete the tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant(tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTenant(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a tenant
         * @param {string} tenantId 
         * @param {boolean} [withImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant(tenantId: string, withImage?: boolean, options?: any): AxiosPromise<TenantDTO> {
            return localVarFp.getTenant(tenantId, withImage, options).then((request) => request(axios, basePath));
        },
        /**
         * this will get the export history and count it entries
         * @summary get tenant export history
         * @param {string} tenantId 
         * @param {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'>} [benefits] Return entries containing all given benefits
         * @param {string} [searchText] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantExportHistory(tenantId: string, benefits?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'>, searchText?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<TenantExportHistoryWithUserListDTO> {
            return localVarFp.getTenantExportHistory(tenantId, benefits, searchText, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list costCenters
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCostCenters(tenantId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<CostCentersDTO> {
            return localVarFp.listCostCenters(tenantId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list departments
         * @param {string} tenantId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDepartments(tenantId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<DepartmentsDTO> {
            return localVarFp.listDepartments(tenantId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list tenants
         * @param {boolean} [hasTenantBilling] false to filter for tenants without a tenant billing configured yet, true to only get tenants which have some configured
         * @param {boolean} [includeEmployeeCount] true to include the count of (non-deleted) employees, default is false
         * @param {boolean} [includeBillingType] true to include the currently active billing type
         * @param {'MAX_PRICE' | 'STATIC' | 'PER_BENEFIT' | 'FREE' | 'MAX_PRICE_BENEFIT_ACTIVATION'} [billingType] 
         * @param {string} [filter] 
         * @param {Array<string>} [tenantIds] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenants(hasTenantBilling?: boolean, includeEmployeeCount?: boolean, includeBillingType?: boolean, billingType?: 'MAX_PRICE' | 'STATIC' | 'PER_BENEFIT' | 'FREE' | 'MAX_PRICE_BENEFIT_ACTIVATION', filter?: string, tenantIds?: Array<string>, page?: number, pageSize?: number, sortBy?: Array<string>, options?: any): AxiosPromise<TenantsDTO> {
            return localVarFp.listTenants(hasTenantBilling, includeEmployeeCount, includeBillingType, billingType, filter, tenantIds, page, pageSize, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {NotifyTenantExportTypeDTO} notifyTenantExportTypeDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyTestAccountExportTypeChange(tenantId: string, notifyTenantExportTypeDTO: NotifyTenantExportTypeDTO, options?: any): AxiosPromise<void> {
            return localVarFp.notifyTestAccountExportTypeChange(tenantId, notifyTenantExportTypeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create mail to send to sales
         * @param {string} tenantId 
         * @param {TenantSalesMailDTO} tenantSalesMailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSalesMail(tenantId: string, tenantSalesMailDTO: TenantSalesMailDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sendSalesMail(tenantId, tenantSalesMailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the tenant
         * @param {string} tenantId 
         * @param {UpdateTenantDTO} updateTenantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant(tenantId: string, updateTenantDTO: UpdateTenantDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateTenant(tenantId, updateTenantDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRegistrationCode operation in TenantApi.
 * @export
 * @interface TenantApiCreateRegistrationCodeRequest
 */
export interface TenantApiCreateRegistrationCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiCreateRegistrationCode
     */
    readonly tenantId: string
}

/**
 * Request parameters for createTenant operation in TenantApi.
 * @export
 * @interface TenantApiCreateTenantRequest
 */
export interface TenantApiCreateTenantRequest {
    /**
     * 
     * @type {CreateTenantDTO}
     * @memberof TenantApiCreateTenant
     */
    readonly createTenantDTO: CreateTenantDTO
}

/**
 * Request parameters for createTestTenant operation in TenantApi.
 * @export
 * @interface TenantApiCreateTestTenantRequest
 */
export interface TenantApiCreateTestTenantRequest {
    /**
     * 
     * @type {CreateTestTenantDTO}
     * @memberof TenantApiCreateTestTenant
     */
    readonly createTestTenantDTO: CreateTestTenantDTO
}

/**
 * Request parameters for createUploadLink operation in TenantApi.
 * @export
 * @interface TenantApiCreateUploadLinkRequest
 */
export interface TenantApiCreateUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiCreateUploadLink
     */
    readonly tenantId: string
}

/**
 * Request parameters for deleteTenant operation in TenantApi.
 * @export
 * @interface TenantApiDeleteTenantRequest
 */
export interface TenantApiDeleteTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiDeleteTenant
     */
    readonly tenantId: string
}

/**
 * Request parameters for getTenant operation in TenantApi.
 * @export
 * @interface TenantApiGetTenantRequest
 */
export interface TenantApiGetTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiGetTenant
     */
    readonly tenantId: string

    /**
     * 
     * @type {boolean}
     * @memberof TenantApiGetTenant
     */
    readonly withImage?: boolean
}

/**
 * Request parameters for getTenantExportHistory operation in TenantApi.
 * @export
 * @interface TenantApiGetTenantExportHistoryRequest
 */
export interface TenantApiGetTenantExportHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiGetTenantExportHistory
     */
    readonly tenantId: string

    /**
     * Return entries containing all given benefits
     * @type {Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'>}
     * @memberof TenantApiGetTenantExportHistory
     */
    readonly benefits?: Array<'BAV' | 'LUNCH' | 'COUPONS' | 'COUPONS_V2' | 'MOBILITY' | 'GIFTS' | 'INTERNET' | 'BIKE' | 'NEWS' | 'FLEX' | 'RECREATION' | 'BIKELEASING' | 'CUSTOM'>

    /**
     * 
     * @type {string}
     * @memberof TenantApiGetTenantExportHistory
     */
    readonly searchText?: string

    /**
     * 
     * @type {number}
     * @memberof TenantApiGetTenantExportHistory
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantApiGetTenantExportHistory
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listCostCenters operation in TenantApi.
 * @export
 * @interface TenantApiListCostCentersRequest
 */
export interface TenantApiListCostCentersRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiListCostCenters
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof TenantApiListCostCenters
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantApiListCostCenters
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listDepartments operation in TenantApi.
 * @export
 * @interface TenantApiListDepartmentsRequest
 */
export interface TenantApiListDepartmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiListDepartments
     */
    readonly tenantId: string

    /**
     * 
     * @type {number}
     * @memberof TenantApiListDepartments
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantApiListDepartments
     */
    readonly pageSize?: number
}

/**
 * Request parameters for listTenants operation in TenantApi.
 * @export
 * @interface TenantApiListTenantsRequest
 */
export interface TenantApiListTenantsRequest {
    /**
     * false to filter for tenants without a tenant billing configured yet, true to only get tenants which have some configured
     * @type {boolean}
     * @memberof TenantApiListTenants
     */
    readonly hasTenantBilling?: boolean

    /**
     * true to include the count of (non-deleted) employees, default is false
     * @type {boolean}
     * @memberof TenantApiListTenants
     */
    readonly includeEmployeeCount?: boolean

    /**
     * true to include the currently active billing type
     * @type {boolean}
     * @memberof TenantApiListTenants
     */
    readonly includeBillingType?: boolean

    /**
     * 
     * @type {'MAX_PRICE' | 'STATIC' | 'PER_BENEFIT' | 'FREE' | 'MAX_PRICE_BENEFIT_ACTIVATION'}
     * @memberof TenantApiListTenants
     */
    readonly billingType?: 'MAX_PRICE' | 'STATIC' | 'PER_BENEFIT' | 'FREE' | 'MAX_PRICE_BENEFIT_ACTIVATION'

    /**
     * 
     * @type {string}
     * @memberof TenantApiListTenants
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TenantApiListTenants
     */
    readonly tenantIds?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof TenantApiListTenants
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TenantApiListTenants
     */
    readonly pageSize?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof TenantApiListTenants
     */
    readonly sortBy?: Array<string>
}

/**
 * Request parameters for notifyTestAccountExportTypeChange operation in TenantApi.
 * @export
 * @interface TenantApiNotifyTestAccountExportTypeChangeRequest
 */
export interface TenantApiNotifyTestAccountExportTypeChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiNotifyTestAccountExportTypeChange
     */
    readonly tenantId: string

    /**
     * 
     * @type {NotifyTenantExportTypeDTO}
     * @memberof TenantApiNotifyTestAccountExportTypeChange
     */
    readonly notifyTenantExportTypeDTO: NotifyTenantExportTypeDTO
}

/**
 * Request parameters for sendSalesMail operation in TenantApi.
 * @export
 * @interface TenantApiSendSalesMailRequest
 */
export interface TenantApiSendSalesMailRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiSendSalesMail
     */
    readonly tenantId: string

    /**
     * 
     * @type {TenantSalesMailDTO}
     * @memberof TenantApiSendSalesMail
     */
    readonly tenantSalesMailDTO: TenantSalesMailDTO
}

/**
 * Request parameters for updateTenant operation in TenantApi.
 * @export
 * @interface TenantApiUpdateTenantRequest
 */
export interface TenantApiUpdateTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantApiUpdateTenant
     */
    readonly tenantId: string

    /**
     * 
     * @type {UpdateTenantDTO}
     * @memberof TenantApiUpdateTenant
     */
    readonly updateTenantDTO: UpdateTenantDTO
}

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @summary create or reset tenant registration code
     * @param {TenantApiCreateRegistrationCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createRegistrationCode(requestParameters: TenantApiCreateRegistrationCodeRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).createRegistrationCode(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a new user and invites a tenant admin
     * @param {TenantApiCreateTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createTenant(requestParameters: TenantApiCreateTenantRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).createTenant(requestParameters.createTenantDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a new user and invites a tenant admin for a test account
     * @param {TenantApiCreateTestTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createTestTenant(requestParameters: TenantApiCreateTestTenantRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).createTestTenant(requestParameters.createTestTenantDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a form POST upload link for uploading the tenant image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {TenantApiCreateUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createUploadLink(requestParameters: TenantApiCreateUploadLinkRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).createUploadLink(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete the tenant
     * @param {TenantApiDeleteTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public deleteTenant(requestParameters: TenantApiDeleteTenantRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).deleteTenant(requestParameters.tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a tenant
     * @param {TenantApiGetTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenant(requestParameters: TenantApiGetTenantRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenant(requestParameters.tenantId, requestParameters.withImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this will get the export history and count it entries
     * @summary get tenant export history
     * @param {TenantApiGetTenantExportHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantExportHistory(requestParameters: TenantApiGetTenantExportHistoryRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenantExportHistory(requestParameters.tenantId, requestParameters.benefits, requestParameters.searchText, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list costCenters
     * @param {TenantApiListCostCentersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public listCostCenters(requestParameters: TenantApiListCostCentersRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).listCostCenters(requestParameters.tenantId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list departments
     * @param {TenantApiListDepartmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public listDepartments(requestParameters: TenantApiListDepartmentsRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).listDepartments(requestParameters.tenantId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list tenants
     * @param {TenantApiListTenantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public listTenants(requestParameters: TenantApiListTenantsRequest = {}, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).listTenants(requestParameters.hasTenantBilling, requestParameters.includeEmployeeCount, requestParameters.includeBillingType, requestParameters.billingType, requestParameters.filter, requestParameters.tenantIds, requestParameters.page, requestParameters.pageSize, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantApiNotifyTestAccountExportTypeChangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public notifyTestAccountExportTypeChange(requestParameters: TenantApiNotifyTestAccountExportTypeChangeRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).notifyTestAccountExportTypeChange(requestParameters.tenantId, requestParameters.notifyTenantExportTypeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create mail to send to sales
     * @param {TenantApiSendSalesMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public sendSalesMail(requestParameters: TenantApiSendSalesMailRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).sendSalesMail(requestParameters.tenantId, requestParameters.tenantSalesMailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the tenant
     * @param {TenantApiUpdateTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public updateTenant(requestParameters: TenantApiUpdateTenantRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).updateTenant(requestParameters.tenantId, requestParameters.updateTenantDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
