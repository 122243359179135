import CalculateIcon from '@mui/icons-material/Calculate';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MapIcon from '@mui/icons-material/Map';
import { Box, Card, Tab, Tabs, useTheme } from '@mui/material';
import { BikeleasingIcon } from 'probonio-shared-ui/component/icon';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { BikeleasingContent, BikeleasingTabs } from '../../module/benefit/bikeleasing';
import { HighlightGuideButton } from '../../module/guiding';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

export const BikeleasingPage: React.FC = () => {
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const navigate = useNavigate();
  const { t } = useTranslation('benefitModule');
  const [tab, setTab] = React.useState<BikeleasingTabs>(BikeleasingTabs.overview);

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: BikeleasingTabs) => {
    setTab(newValue);
  }, []);

  // const { contract: activeContract, isLoading } = useCurrentBikeContract();
  // const { data: bikeBenefitDetails } = useBikeDetailsQuery();

  const navigateInfo = useCallback(() => {
    navigate('info');
  }, [navigate]);

  return (
    // <Box style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.paper}
      header={
        <Card sx={{ borderRadius: 0, boxShadow: 0 }}>
          <AppToolbar
            title={
              <Box display="flex" alignItems="center">
                <BikeleasingIcon width="100%" />
              </Box>
            }
            // color={ theme.palette.background.default}
            backButton={<BackIconButton onClick={backNavigator.back} />}
            actionButton={
              <HighlightGuideButton
                name={t('benefit.BIKELEASING.name')}
                header={t('benefit.BIKELEASING.firstHelpGuide.header')}
                text={t('benefit.BIKELEASING.firstHelpGuide.text')}
                onClick={navigateInfo}
              />
            }
          />
          <Box display="flex" justifyContent="center">
            {/* <BikeleasingStatus /> */}
          </Box>
          <Tabs value={tab} onChange={handleChange}>
            <Tab value={BikeleasingTabs.overview} icon={<LightbulbIcon />} sx={{ flex: 1 }} />
            <Tab value={BikeleasingTabs.calculator} icon={<CalculateIcon />} sx={{ flex: 1 }} />
            <Tab value={BikeleasingTabs.vendorMap} icon={<MapIcon />} sx={{ flex: 1 }} />
          </Tabs>
        </Card>
      }
    >
      <BikeleasingContent tab={tab} />
    </MobileLayout>
    // </Box>
  );
};
