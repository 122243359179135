import { Box, Typography, useTheme } from '@mui/material';
import { BasicAlert } from '../../../component/alert';
import { Capacitor } from '@capacitor/core';
import { useBikeleasingDetails } from './useBikeleasingDetails';
import { Trans, useTranslation } from 'react-i18next';

export const BikeleasingCalculator: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('benefitModule');
  const backgroundColor = theme.palette.mode === 'dark' ? theme.palette.bikeleasing.secondary : theme.palette.background.paper;

  const bikeleasingBenefitDetails = useBikeleasingDetails();

  const isAndroid = Capacitor.getPlatform() === 'android';

  return (
    <Box sx={{ backgroundColor }} height="100%" display="flex" flexDirection="column">
      {isAndroid && (
        <BasicAlert sx={{ margin: 0.5, marginTop: 1 }} icon={false} severity="info">
          <Typography variant="body2" paddingX={1}>
            {t('benefit.BIKELEASING.calculatorAndroidInfo')}
          </Typography>
          <Typography variant="body2" paddingX={1}>
            <Trans i18nKey={'benefitModule:benefit.BIKELEASING.calculatorAndroidInfoLink'}>
              <a
                target="_blank"
                href={`${import.meta.env.VITE_BIKELEASING_CALCULATOR_URL}&code=${bikeleasingBenefitDetails?.bikeleasingCondition.companyCode}`}
                rel="noreferrer"
              ></a>
            </Trans>
          </Typography>
        </BasicAlert>
      )}
      <iframe
        src={`${import.meta.env.VITE_BIKELEASING_CALCULATOR_URL}&code=${bikeleasingBenefitDetails?.bikeleasingCondition.companyCode}`}
        seamless
        style={{
          textAlign: 'center',
          outline: 'none',
          border: 'none',
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
  );
};
