import { Alert, Box, Button, Grid, IconButton, Modal, Paper, Snackbar } from '@mui/material';
import CasinoIcon from '@mui/icons-material/Casino';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useCallback, useEffect, useState } from 'react';
import MagicCounterPlayer from './MagicCounterPlayer';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { useTranslation } from 'react-i18next';
import Confetti from 'canvas-confetti';

interface Props {
  open: boolean;
  handleCloseCounter: () => void;
}

const MagicCounter: React.FC<Props> = ({ open, handleCloseCounter }) => {
  const { t } = useTranslation('magicEasterEgg');
  const [openDice, setOpenDice] = useState(false);
  const [playerOneHealth, setPlayerOneHealth] = useState(20);
  const [playerTwoHealth, setPlayerTwoHealth] = useState(20);

  const keepAwake = useCallback(async () => {
    await KeepAwake.keepAwake();
  }, []);

  const allowSleep = useCallback(async () => {
    await KeepAwake.allowSleep();
  }, []);

  useEffect(() => {
    if (!open) {
      return;
    }
    void keepAwake();
    return () => {
      void allowSleep();
    };
  }, [allowSleep, keepAwake, open]);

  const confetti = Confetti.create(undefined, { resize: true });

  useEffect(() => {
    if (playerOneHealth === 0 || playerTwoHealth === 0) {
      void confetti({
        particleCount: 300,
        spread: 360,
        scalar: 2,
        zIndex: 1301,
        decay: 0.9,
        origin: { x: 0.5, y: playerOneHealth === 0 ? 0.73 : 0.27 },
        startVelocity: 20,
        disableForReducedMotion: true,
        gravity: playerOneHealth === 0 ? 1 : -1,
      });
      setTimeout(() => confetti.reset(), 5500);
    }
  }, [confetti, playerOneHealth, playerTwoHealth]);

  const handlePlayerOneHealth = useCallback(
    (health: number) => {
      setPlayerOneHealth(playerOneHealth + health);
    },
    [playerOneHealth],
  );

  const handlePlayerTwoHealth = useCallback(
    (health: number) => {
      setPlayerTwoHealth(playerTwoHealth + health);
    },
    [playerTwoHealth],
  );

  const [dicePlayerWin, setDicePlayerWin] = useState(0);

  const resetHealth = useCallback(() => {
    setPlayerOneHealth(20);
    setPlayerTwoHealth(20);
  }, []);

  const handleDiceClose = useCallback(() => {
    setOpenDice(false);
  }, []);

  const handleDiceRoll = useCallback(() => {
    const rand = Math.random();
    setDicePlayerWin(rand > 0.5 ? 0 : 1);
    setOpenDice(true);
  }, []);

  const handleClose = useCallback(() => {
    resetHealth();
    handleCloseCounter();
  }, [handleCloseCounter, resetHealth]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper sx={{ height: '100vh' }}>
        <MagicCounterPlayer turnScreen handleHealthChange={handlePlayerOneHealth} health={playerOneHealth} />
        <Box>
          <Grid container justifyContent="center">
            <Grid item>
              <IconButton size="large" onClick={handleDiceRoll}>
                <CasinoIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton size="large" onClick={resetHealth}>
                <RestartAltIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Button onClick={handleClose}>{t('close')}</Button>
            </Grid>
          </Grid>
        </Box>
        <MagicCounterPlayer handleHealthChange={handlePlayerTwoHealth} health={playerTwoHealth} />
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ transform: 'rotate(180deg)' }}
          open={openDice}
          onClose={handleDiceClose}
          key="player1"
        >
          <Alert onClose={handleDiceClose} variant="filled" severity={!dicePlayerWin ? 'success' : 'error'} sx={{ width: '100%' }}>
            {!dicePlayerWin ? t('youStart') : t('enemyStart')}
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={openDice}
          onClose={handleDiceClose}
          key="player2"
        >
          <Alert onClose={handleDiceClose} variant="filled" severity={dicePlayerWin ? 'success' : 'error'} sx={{ width: '100%' }}>
            {dicePlayerWin ? t('youStart') : t('enemyStart')}
          </Alert>
        </Snackbar>
      </Paper>
    </Modal>
  );
};

export default MagicCounter;
