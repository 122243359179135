import { Capacitor } from '@capacitor/core';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Rating, useTheme } from '@mui/material';
import { FeedbackApiSendFeedbackRequest } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis, useEmployeeQuery } from 'probonio-shared-ui/module/api';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../component/form/TextFieldControl';
import { FeedbackBox } from './FeedbackBox';
import { useFeedbackMutation } from './useFeedbackMutation';

interface GiveFeedbackProps {
  onStep: (value: number) => void;
  onClose: () => void;
}

const LOCALSTORAGE_KEY = 'feedbackCardOpened';
const appStore = 'https://apps.apple.com/app/id1617828643?action=write-review';
const googlePlay = 'https://play.google.com/store/apps/details?id=de.probonio.probonio&gl=DE';
const googleReview = 'https://g.page/r/CXDA4GkjgoJYEBM/review';

const getAppStoreUrl = (): string => {
  if (Capacitor.getPlatform() === 'ios') {
    return appStore;
  } else if (Capacitor.getPlatform() === 'android') {
    return googlePlay;
  } else {
    return googleReview;
  }
};

const GiveFeedback: React.FC<GiveFeedbackProps> = ({ onStep, onClose }) => {
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();

  const handleFeedback = useCallback(
    (e: unknown, value: number | null) => {
      if (value && value < 4) {
        onStep(2);
      } else {
        onStep(3);
      }
    },
    [onStep],
  );

  return (
    <FeedbackBox
      title={t('feedbackVisualization.giveFeedback.title')}
      onClose={onClose}
      description={t('feedbackVisualization.giveFeedback.description')}
    >
      <Rating sx={{ fontSize: 50, color: theme.palette.primary.main }} onChange={handleFeedback} />
    </FeedbackBox>
  );
};

type FeedbackFields = Pick<FeedbackApiSendFeedbackRequest, 'subject' | 'message' | 'file'>;

const FeedbackSupport: React.FC<GiveFeedbackProps> = ({ onClose }) => {
  const { t } = useTranslation('benefitModule');

  const mutation = useFeedbackMutation(onClose);

  const { control, getValues, handleSubmit } = useForm<FeedbackFields>({
    defaultValues: {
      subject: '',
      message: '',
    },
  });

  const handleSubmitFeedback = useCallback(() => {
    mutation.mutate({ subject: 'App Review Feedback', message: getValues('message') });
  }, [getValues, mutation]);

  return (
    <FeedbackBox
      title={t('feedbackVisualization.feedbackSupport.title')}
      onClose={onClose}
      description={t('feedbackVisualization.feedbackSupport.description')}
    >
      <Box component={'form'} onSubmit={handleSubmit(handleSubmitFeedback)} width="100%" display={'flex'} gap={1}>
        <TextFieldControl
          control={control}
          name="message"
          multiline={true}
          maxRows={3}
          minRows={2}
          inputProps={{ style: { fontSize: 'body2', lineHeight: 1.5 } }}
          rules={{ required: true }}
          sx={{ flexGrow: 1, '& .MuiInputBase-root': { padding: 0.5 } }}
        />
        <LoadingButton variant="contained" type="submit" loading={mutation.isPending}>
          <SendIcon />
        </LoadingButton>
      </Box>
    </FeedbackBox>
  );
};

const FeedbackAppStore: React.FC<GiveFeedbackProps> = ({ onClose }) => {
  const { t } = useTranslation('benefitModule');

  const handleReview = useCallback(() => {
    window.open(getAppStoreUrl(), '_blank');
    onClose();
  }, [onClose]);

  return (
    <FeedbackBox
      title={t('feedbackVisualization.feedbackAppStore.title')}
      onClose={onClose}
      description={t('feedbackVisualization.feedbackAppStore.description')}
    >
      <Button variant="contained" fullWidth onClick={handleReview}>
        {t('feedbackVisualization.feedbackAppStore.rateNow')}
      </Button>
    </FeedbackBox>
  );
};

export const FeedbackVisualization: React.FC = () => {
  const [step, setStep] = useState(0);

  const { data: activeBenefits } = useEmployeeQuery(['benefits', apis.benefits.getBenefits.name], params =>
    apis.benefits.getBenefits({ ...params }).then(res => res.data),
  );

  useEffect(() => {
    const fullAmountReceived = activeBenefits?.benefits.reduce((sum, benefit) => {
      const statsHistory = benefit.statistics?.history || [];
      return sum + (statsHistory[statsHistory.length - 1] || 0);
    }, 0);
    if (fullAmountReceived! >= 10000 && localStorage.getItem(LOCALSTORAGE_KEY) !== 'opened') {
      setStep(1);
    }
  }, [activeBenefits?.benefits]);

  const handleStep = useCallback((value: number) => {
    setStep(value);
  }, []);
  const handleClose = useCallback(() => {
    localStorage.setItem(LOCALSTORAGE_KEY, 'opened');
    handleStep(0);
  }, [handleStep]);

  if (step === 0) {
    return <></>;
  } else {
    return (
      <>
        {step === 1 && <GiveFeedback onStep={handleStep} onClose={handleClose} />}
        {step === 2 && <FeedbackSupport onStep={handleStep} onClose={handleClose} />}
        {step === 3 && <FeedbackAppStore onStep={handleStep} onClose={handleClose} />}
      </>
    );
  }
};

export default FeedbackVisualization;
